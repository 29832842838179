import { ENV } from '../configs/appConfig';
import axios from '../services/httpService';
import Developer from '@src/assets/images/engineers/development.svg';
import Lead from '@src/assets/images/engineers/Lead.svg';
import MainDeveloper from '@src/assets/images/engineers/MainDeveloper.svg';
import Designer from '@src/assets/images/engineers/designer.svg';
import Devop from '@src/assets/images/engineers/devops.svg';
import Security from '@src/assets/images/engineers/security.svg';
import SQA from '@src/assets/images/engineers/bug.svg';
import SQALead from '@src/assets/images/engineers/SQALead.svg';
import Content from '@src/assets/images/engineers/content.svg';
import Marketing from '@src/assets/images/engineers/marketing.svg';
import Admin from '@src/assets/images/engineers/admin.svg';
import HR from '@src/assets/images/engineers/human-resource.svg';
import BusinessAnalyst from '@src/assets/images/engineers/Business Analyst.svg';
import Business from '@src/assets/images/engineers/business.svg';
import CSM from '@src/assets/images/engineers/CSM.svg';
import EmailMarketing from '@src/assets/images/engineers/email marketing.svg';
import PaidAds from '@src/assets/images/engineers/Paid ads.svg';
import ProjectManager from '@src/assets/images/engineers/project manager.svg';
import Sales from '@src/assets/images/engineers/sales.svg';
import SEO from '@src/assets/images/engineers/SEO.svg';
import SocialMediaMarketer from '@src/assets/images/engineers/social-media Marketer.svg';
import anyDep from '@src/assets/images/engineers/any Dept.svg';
import dayjs from 'dayjs';
import moment from 'moment';

export const USER_ROLES = {
  READ_ONLY: 'read_only',
  READ_WRITE: 'read_write',
};

export const DEFAULT_PERMISSIONS = {
  permissions: {
    resources: { read: false, write: false },
    projects: { read: false, write: false },
    engagement_leads: { read: false, write: false },
    logs: { read: false, write: false },
    project_managers: { read: false, write: false },
    csm: { read: false, write: false },
  },
};

export const AVAILABILITY = [
  { value: 0, label: 'Fully Occupied' },
  { value: 2, label: '1~2 hours' },
  { value: 4, label: '3~4 hours' },
  { value: 6, label: '5~6 hours' },
  { value: 8, label: 'Fully Available' },
];
export const ENGAGEMENT_CAPACITY = [
  { value: 0, label: 'Fully Available' },
  { value: 2, label: '1~2 hours' },
  { value: 4, label: '3~4 hours' },
  { value: 6, label: '5~6 hours' },
  { value: 8, label: 'Fully Occupied' },
];
export const EXPECTED_DATE_LABELS = {
  probation_label: 'Probations',
  increment_label: 'Increments',
};

export const TAGS_OPTIONS = [
  { value: 'Active', label: 'Active', color: '#498205' },
  { value: 'Won', label: 'Won', color: '#c19c00' },
  { value: 'Dropped', label: 'Dropped', color: '#da3b01' },
  { value: 'Sleeping', label: 'Sleeping', color: '#004e8c' },
];

export const SLACK_REDIRECT_URL = 'https://invozone.slack.com/app_redirect?channel=';

export const EMAIL_REDIRECT_URL = 'https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=';

export const PRIORITY_OPTIONS = [
  { value: 'low', label: 'Low', color: '#F1E1A6' },
  { value: 'medium', label: 'Medium', color: '#C3E5AE' },
  { value: 'high', label: 'High', color: '#FF6B6B' },
];

export const ENGAGEMENT_TYPE = [
  { label: 'Billed', value: 1 },
  // { label: 'Shadow', value: 4 },
  { label: 'Support', value: 5 },
  { label: 'Parked', value: 2 },
  { label: 'Training', value: 3 },
];
export const ENGAGEMENT_TYPE_CLIENT = [
  { label: 'Billed', value: 1 },
  { label: 'Shadow', value: 4 },
  { label: 'Support', value: 5 },
  { label: 'Parked', value: 2 },
  { label: 'Training', value: 3 },
];
export const ENGAGEMENT_TYPE_INHOUSE = [
  { label: 'Support', value: 5 },
  { label: 'Parked', value: 2 },
  { label: 'Training', value: 3 },
];
export const cleanup = () => {
  localStorage.removeItem('toHighlightOrFilter');
};
export const handleEmailPhone = async (sendId, receiveId, act, em, nm) => {
  const request_body = {
    senderId: sendId,
    receiverId: receiveId,
    action: act,
    email: em,
    name: nm,
  };
  const baseUrl = ENV.baseUrl;
  try {
    const response = await axios.post(`/logs/iconClickEmail`, request_body);
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const handlePhone = async (sendId, receiveId, act, em, nm) => {
  const request_body = {
    senderId: sendId,
    receiverId: receiveId,
    action: act,
    email: em,
    name: nm,
  };
  const baseUrl = ENV.baseUrl;
  try {
    const response = await axios.post(`/logs/iconClickPhone`, request_body);
  } catch (error) {
    console.log(error);
  }
};

export const PAGINATION_PAGE_SIZES = ['10', '20', '80', '160', '800'];
export const DEFAULT_HTTP_ERROR = 'Something went wrong, please try again later';

export const humanize = (str) => {
  var frags = str?.split('_');
  for (let i = 0; i < frags?.length; i++) {
    if (frags[i] != 'TO') frags[i] = frags[i]?.charAt(0)?.toUpperCase() + frags[i]?.slice(1)?.toLowerCase();
    else frags[i] = frags[i]?.toLowerCase();
  }
  return frags?.join(' ');
};

export const roundOffExp = (exp) => Math.round(exp * 2) / 2.0;

export const STATUSE_OPTIONS = [
  { value: 'PRESALE', label: 'Presale' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'PAUSED', label: 'Paused' },
  { value: 'CLOSING', label: 'Closing' },
  { value: 'CLOSED', label: 'Closed' },
];

export const BILLING_FREQUENCY = [
  { value: 'HOURLY', label: 'Hourly' },
  { value: 'WEEKLY', label: 'Weekly' },
  { value: 'MONTHLY', label: 'Monthly' },
  { value: 'BI_WEEKLY', label: 'Bi-Weekly' },
  { value: 'BI_MONTHLY', label: 'Bi-Monthly' },
  { value: 'MILESTONE', label: 'Milestone' },
];
export const ENGAGEMENT_OPTIONS = [
  { value: 'MONTHLY', label: 'Monthly' },
  { value: 'HOURLY', label: 'Hourly' },
  { value: 'FIXED_GIGZ', label: 'Fixed GiGs' },
]

export const CURRENCY = [
  {
    value: 'Afghan Afghani',
    label: 'Afghan Afghani',
  },
  {
    value: 'Albanian Lek',
    label: 'Albanian Lek',
  },
  {
    value: 'Algerian Dinar',
    label: 'Algerian Dinar',
  },
  {
    value: 'Angolan Kwanza',
    label: 'Angolan Kwanza',
  },
  {
    value: 'Argentine Peso',
    label: 'Argentine Peso',
  },
  {
    value: 'Armenian Dram',
    label: 'Armenian Dram',
  },
  {
    value: 'Aruban Florin',
    label: 'Aruban Florin',
  },
  {
    value: 'Australian Dollar',
    label: 'Australian Dollar',
  },
  {
    value: 'Azerbaijani Manat',
    label: 'Azerbaijani Manat',
  },
  {
    value: 'Bahamian Dollar',
    label: 'Bahamian Dollar',
  },
  {
    value: 'Bahraini Dinar',
    label: 'Bahraini Dinar',
  },
  {
    value: 'Bangladeshi Taka',
    label: 'Bangladeshi Taka',
  },
  {
    value: 'Barbadian Dollar',
    label: 'Barbadian Dollar',
  },
  {
    value: 'Basotho Loti',
    label: 'Basotho Loti',
  },
  {
    value: 'Belarusian Ruble',
    label: 'Belarusian Ruble',
  },
  {
    value: 'Belizean Dollar',
    label: 'Belizean Dollar',
  },
  {
    value: 'Bermudian Dollar',
    label: 'Bermudian Dollar',
  },
  {
    value: 'Bhutanese Ngultrum',
    label: 'Bhutanese Ngultrum',
  },
  {
    value: 'Bolivian Bolíviano',
    label: 'Bolivian Bolíviano',
  },
  {
    value: 'Bosnia and Herzegovina Convertible Mark',
    label: 'Bosnia and Herzegovina Convertible Mark',
  },
  {
    value: 'Botswana Pula',
    label: 'Botswana Pula',
  },
  {
    value: 'Brazilian Real',
    label: 'Brazilian Real',
  },
  {
    value: 'British Pound Sterling',
    label: 'British Pound Sterling',
  },
  {
    value: 'Bruneian Dollar',
    label: 'Bruneian Dollar',
  },
  {
    value: 'Bulgarian Lev',
    label: 'Bulgarian Lev',
  },
  {
    value: 'Burmese Kyat',
    label: 'Burmese Kyat',
  },
  {
    value: 'Burundian Franc',
    label: 'Burundian Franc',
  },
  {
    value: 'Cambodian Riel',
    label: 'Cambodian Riel',
  },
  {
    value: 'Canadian Dollar',
    label: 'Canadian Dollar',
  },
  {
    value: 'Cape Verdean Escudo',
    label: 'Cape Verdean Escudo',
  },
  {
    value: 'Cayman Islands Dollar',
    label: 'Cayman Islands Dollar',
  },
  {
    value: 'Chilean Peso',
    label: 'Chilean Peso',
  },
  {
    value: 'Chinese Yuan',
    label: 'Chinese Yuan',
  },
  {
    value: 'Colombian Peso',
    label: 'Colombian Peso',
  },
  {
    value: 'Comorian Franc',
    label: 'Comorian Franc',
  },
  {
    value: 'Congolese Franc',
    label: 'Congolese Franc',
  },
  {
    value: 'Costa Rican Colón',
    label: 'Costa Rican Colón',
  },
  {
    value: 'Croatian Kuna',
    label: 'Croatian Kuna',
  },
  {
    value: 'Cuban Convertible Peso',
    label: 'Cuban Convertible Peso',
  },
  {
    value: 'Cuban Peso',
    label: 'Cuban Peso',
  },
  {
    value: 'Czech Koruna',
    label: 'Czech Koruna',
  },
  {
    value: 'Danish Krone',
    label: 'Danish Krone',
  },
  {
    value: 'Djiboutian Franc',
    label: 'Djiboutian Franc',
  },
  {
    value: 'Dominican Peso',
    label: 'Dominican Peso',
  },
  {
    value: 'East Caribbean Dollar',
    label: 'East Caribbean Dollar',
  },
  {
    value: 'Egyptian Pound',
    label: 'Egyptian Pound',
  },
  {
    value: 'Eritrean Nakfa',
    label: 'Eritrean Nakfa',
  },
  {
    value: 'Ethiopian Birr',
    label: 'Ethiopian Birr',
  },
  {
    value: 'Euro',
    label: 'Euro',
  },
  {
    value: 'Falkland Islands Pound',
    label: 'Falkland Islands Pound',
  },
  {
    value: 'Fijian Dollar',
    label: 'Fijian Dollar',
  },
  {
    value: 'Gambian Dalasi',
    label: 'Gambian Dalasi',
  },
  {
    value: 'Georgian Lari',
    label: 'Georgian Lari',
  },
  {
    value: 'Ghanaian Cedi',
    label: 'Ghanaian Cedi',
  },
  {
    value: 'Gibraltar Pound',
    label: 'Gibraltar Pound',
  },
  {
    value: 'Guatemalan Quetzal',
    label: 'Guatemalan Quetzal',
  },
  {
    value: 'Guernsey Pound',
    label: 'Guernsey Pound',
  },
  {
    value: 'Guinean Franc',
    label: 'Guinean Franc',
  },
  {
    value: 'Guyanese Dollar',
    label: 'Guyanese Dollar',
  },
  {
    value: 'Haitian Gourde',
    label: 'Haitian Gourde',
  },
  {
    value: 'Honduran Lempira',
    label: 'Honduran Lempira',
  },
  {
    value: 'Hong Kong Dollar',
    label: 'Hong Kong Dollar',
  },
  {
    value: 'Hungarian Forint',
    label: 'Hungarian Forint',
  },
  {
    value: 'Icelandic Króna',
    label: 'Icelandic Króna',
  },
  {
    value: 'Indian Rupee',
    label: 'Indian Rupee',
  },
  {
    value: 'Indonesian Rupiah',
    label: 'Indonesian Rupiah',
  },
  {
    value: 'Iranian Rial',
    label: 'Iranian Rial',
  },
  {
    value: 'Iraqi Dinar',
    label: 'Iraqi Dinar',
  },
  {
    value: 'Isle of Man Pound',
    label: 'Isle of Man Pound',
  },
  {
    value: 'Israeli New Shekel',
    label: 'Israeli New Shekel',
  },
  {
    value: 'Jamaican Dollar',
    label: 'Jamaican Dollar',
  },
  {
    value: 'Japanese Yen',
    label: 'Japanese Yen',
  },
  {
    value: 'Jersey Pound',
    label: 'Jersey Pound',
  },
  {
    value: 'Jordanian Dinar',
    label: 'Jordanian Dinar',
  },
  {
    value: 'Kazakhstani Tenge',
    label: 'Kazakhstani Tenge',
  },
  {
    value: 'Kenyan Shilling',
    label: 'Kenyan Shilling',
  },
  {
    value: 'Kuwaiti Dinar',
    label: 'Kuwaiti Dinar',
  },
  {
    value: 'Kyrgyzstani Som',
    label: 'Kyrgyzstani Som',
  },
  {
    value: 'Lao Kip',
    label: 'Lao Kip',
  },
  {
    value: 'Lebanese Pound',
    label: 'Lebanese Pound',
  },
  {
    value: 'Liberian Dollar',
    label: 'Liberian Dollar',
  },
  {
    value: 'Libyan Dinar',
    label: 'Libyan Dinar',
  },
  {
    value: 'Macau Pataca',
    label: 'Macau Pataca',
  },
  {
    value: 'Macedonian Denar',
    label: 'Macedonian Denar',
  },
  {
    value: 'Malagasy Ariary',
    label: 'Malagasy Ariary',
  },
  {
    value: 'Malawian Kwacha',
    label: 'Malawian Kwacha',
  },
  {
    value: 'Malaysian Ringgit',
    label: 'Malaysian Ringgit',
  },
  {
    value: 'Maldivian Rufiyaa',
    label: 'Maldivian Rufiyaa',
  },
  {
    value: 'Mauritanian Ouguiya',
    label: 'Mauritanian Ouguiya',
  },
  {
    value: 'Mauritian Rupee',
    label: 'Mauritian Rupee',
  },
  {
    value: 'Mexican Peso',
    label: 'Mexican Peso',
  },
  {
    value: 'Moldovan Leu',
    label: 'Moldovan Leu',
  },
  {
    value: 'Mongolian Tughrik',
    label: 'Mongolian Tughrik',
  },
  {
    value: 'Moroccan Dirham',
    label: 'Moroccan Dirham',
  },
  {
    value: 'Mozambican Metical',
    label: 'Mozambican Metical',
  },
  {
    value: 'Namibian Dollar',
    label: 'Namibian Dollar',
  },
  {
    value: 'Nepalese Rupee',
    label: 'Nepalese Rupee',
  },
  {
    value: 'Netherlands Antillean Guilder',
    label: 'Netherlands Antillean Guilder',
  },
  {
    value: 'New Zealand Dollar',
    label: 'New Zealand Dollar',
  },
  {
    value: 'Ni-Vanuatu Vatu',
    label: 'Ni-Vanuatu Vatu',
  },
  {
    value: 'Nicaraguan Córdoba',
    label: 'Nicaraguan Córdoba',
  },
  {
    value: 'Nigerian Naira',
    label: 'Nigerian Naira',
  },
  {
    value: 'North Korean Won',
    label: 'North Korean Won',
  },
  {
    value: 'Norwegian Krone',
    label: 'Norwegian Krone',
  },
  {
    value: 'Omani Rial',
    label: 'Omani Rial',
  },
  {
    value: 'Pakistani Rupee',
    label: 'Pakistani Rupee',
  },
  {
    value: 'Panamanian Balboa',
    label: 'Panamanian Balboa',
  },
  {
    value: 'Papua New Guinean Kina',
    label: 'Papua New Guinean Kina',
  },
  {
    value: 'Paraguayan Guarani',
    label: 'Paraguayan Guarani',
  },
  {
    value: 'Peruvian Sol',
    label: 'Peruvian Sol',
  },
  {
    value: 'Philippine Peso',
    label: 'Philippine Peso',
  },
  {
    value: 'Polish Złoty',
    label: 'Polish Złoty',
  },
  {
    value: 'Pounds',
    label: 'Pounds',
  },
  {
    value: 'Qatari Riyal',
    label: 'Qatari Riyal',
  },
  {
    value: 'Romanian Leu',
    label: 'Romanian Leu',
  },
  {
    value: 'Russian Ruble',
    label: 'Russian Ruble',
  },
  {
    value: 'Rwandan Franc',
    label: 'Rwandan Franc',
  },
  {
    value: 'Saint Helenian Pound',
    label: 'Saint Helenian Pound',
  },
  {
    value: 'Salvadoran Colón',
    label: 'Salvadoran Colón',
  },
  {
    value: 'Samoan Tālā',
    label: 'Samoan Tālā',
  },
  {
    value: 'São Toméan Dobra',
    label: 'São Toméan Dobra',
  },
  {
    value: 'Saudi Arabian Riyal',
    label: 'Saudi Arabian Riyal',
  },
  {
    value: 'Serbian Dinar',
    label: 'Serbian Dinar',
  },
  {
    value: 'Seychellois Rupee',
    label: 'Seychellois Rupee',
  },
  {
    value: 'Sierra Leonean Leone',
    label: 'Sierra Leonean Leone',
  },
  {
    value: 'Singapore Dollar',
    label: 'Singapore Dollar',
  },
  {
    value: 'Solomon Islander Dollar',
    label: 'Solomon Islander Dollar',
  },
  {
    value: 'Somali Shilling',
    label: 'Somali Shilling',
  },
  {
    value: 'South African Rand',
    label: 'South African Rand',
  },
  {
    value: 'South Korean Won',
    label: 'South Korean Won',
  },
  {
    value: 'South Sudanese Pound',
    label: 'South Sudanese Pound',
  },
  {
    value: 'Sri Lankan Rupee',
    label: 'Sri Lankan Rupee',
  },
  {
    value: 'Sudanese Pound',
    label: 'Sudanese Pound',
  },
  {
    value: 'Surinamese Dollar',
    label: 'Surinamese Dollar',
  },
  {
    value: 'Swazi Lilangeni',
    label: 'Swazi Lilangeni',
  },
  {
    value: 'Swedish Krona',
    label: 'Swedish Krona',
  },
  {
    value: 'Swiss Franc',
    label: 'Swiss Franc',
  },
  {
    value: 'Syrian Pound',
    label: 'Syrian Pound',
  },
  {
    value: 'Taiwan New Dollar',
    label: 'Taiwan New Dollar',
  },
  {
    value: 'Tajikistani Somoni',
    label: 'Tajikistani Somoni',
  },
  {
    value: 'Tanzanian Shilling',
    label: 'Tanzanian Shilling',
  },
  {
    value: 'Thai Baht',
    label: 'Thai Baht',
  },
  {
    value: 'Tongan Paʻanga',
    label: 'Tongan Paʻanga',
  },
  {
    value: 'Trinidadian Dollar',
    label: 'Trinidadian Dollar',
  },
  {
    value: 'Tunisian Dinar',
    label: 'Tunisian Dinar',
  },
  {
    value: 'Turkish Lira',
    label: 'Turkish Lira',
  },
  {
    value: 'Turkmenistani Manat',
    label: 'Turkmenistani Manat',
  },
  {
    value: 'Ugandan Shilling',
    label: 'Ugandan Shilling',
  },
  {
    value: 'Ukrainian Hryvnia',
    label: 'Ukrainian Hryvnia',
  },
  {
    value: 'United Arab Emirates Dirham',
    label: 'United Arab Emirates Dirham',
  },
  {
    value: 'United States Dollar',
    label: 'United States Dollar',
  },
  {
    value: 'Uruguayan Peso',
    label: 'Uruguayan Peso',
  },
  {
    value: 'Uzbekistani Som',
    label: 'Uzbekistani Som',
  },
  {
    value: 'Venezuelan Bolívar Soberano',
    label: 'Venezuelan Bolívar Soberano',
  },
  {
    value: 'Vietnamese Đồng',
    label: 'Vietnamese Đồng',
  },
  {
    value: 'Yemeni Rial',
    label: 'Yemeni Rial',
  },
  {
    value: 'Zambian Kwacha',
    label: 'Zambian Kwacha',
  },
  { value: 'Other', label: 'Other' },
];

export const INTRODUCED_AS = [
  { value: 'AGENCY', label: 'Agency to Client' },
  { value: 'INDIVIDUAL_PROFILE', label: 'Individual to Client' },
];

export const SOURCE_OPTIONS = [
  { value: 'COLDCALL', label: 'Cold Call' },
  { value: 'EMAILOUTREACH', label: 'Email Outreach' },
  { value: 'INTERNATIONALEVENT', label: 'International Event' },
  { value: 'INBOUNDFROMWEBSITE', label: 'Inbound from Website' },
  { value: 'LINKEDIN', label: 'LinkedIn' },
  { value: 'UPWORK', label: 'Upwork' },
  { value: 'INBOUNDWALKIN', label: 'Inbound Walk-In' },
  { value: 'INBOUNDAFFILIATE', label: 'Inbound Affiliate' },
  { value: 'OTHERS', label: 'Others' },
];

export const STATUS_OPTIONS_FOR_ONBOARDING = [
  { value: 'PRESALE', label: 'Pre-Sale' },
  { value: 'ACTUAL_PROJECT', label: 'Actual Project' },
];
export const SERVICE_MODE = [
  { value: 'AUGMENTATION', label: 'Augmentation / Dedicated Resource' },
  { value: 'FIXED_GIGZ', label: 'Fixed GiGs (Milestone Based)' },
  { value: 'YET_TO_BE_DECIDED', label: 'Yet To Be Decided' },
];
export const TRIAL_DURATION = [
  { value: 'PAID_TRIAL', label: 'Yes but Paid' },
  { value: 'FREE_TRIAL', label: 'Free Trial' },
  { value: 'NO_TRIAL', label: 'No Trial' },
];

export const COMMUNICATION_MODE = [
  { value: 'CLIENT_SLACK', label: 'Client Own Slack' },
  { value: 'DISCORD', label: 'Discord' },
  { value: 'EMAIL', label: 'Email' },
  { value: 'INVOZONE_SLACK', label: 'InvoZone Slack' },
  { value: 'MS_TEAM', label: 'MS Teams' },
  { value: 'SKYPE', label: 'Skype' },
  { value: 'TELEGRAM', label: 'Telegram' },
  { value: 'UPWORK', label: 'Upwork' },
  { value: 'WHATSAPP', label: 'Whatsapp' },
  { value: 'OTHER', label: 'Other' },
];

export const AUGMENTATION = [
  { value: 'HOURLY', label: 'Hourly' },
  { value: 'MONTHLY', label: 'Monthly' },
];

export const onBoardEmployeeDefaultData = {
  name: null,
  companyNumber: null,
  email: null,
  designationId: null,
  reporting_manager: null,
  work_shift: null,
  working_location: null,
  fatherName: null,
  personalEmail: null,
  phone_no: null,
  address: null,
  country: null,
  cnic: null,
  bankTitle: null,
  ibanNumber: null,
  accountTitle: null,
  bankAddress: null,
  dob: null,
  gender: null,
  maritalStatus: null,
  emergencyContactName: null,
  emergencyContactNumber: null,
  emergencyContactRelation: null,
  certificationLevel: null,
  fieldOfStudy: null,
  school: null,
  scorePercentage: null,
  degreePassingYear: null,
  departmentId: null,
  subDepartmentId: null,
  contract: null,
  job_status: null,
  contract_start_date: null,
  expected_end_of_probation_date: null,
  end_of_probation_date: null,
  expectedEndOfInternshipDate: null,
  endOfInternshipDate: null,
  permanent_date: null,
  notice_date: null,
  notice_end_date: null,
  currentSalary: null,
  previousSalary: null,
  increment_date: null,
  contract_end_date: null,
  attendanceMode: null,
  totalAvailability: null,
  biometric_id: null,
};

export const releaseCapacity = (engagementCapacity, currentAvailability, totalAvailability) => {
  let arr = [];
  let options = engagementCapacity + currentAvailability;
  while (options) {
    arr.push({ value: options, label: options === totalAvailability ? `Fully Occupied` : `${options - 1}~${options} hours` });
    options = options - 2;
  }
  arr.push({ value: options, label: `Fully Available` });
  const capacityOptions = arr.sort((a, b) => {
    return a.value - b.value;
  });
  return capacityOptions;
};

export const resourceRoleLogo = (role) => {
  const roleImage =
    role === 'SQA Engineer'
      ? SQA
      : role === 'SQA Lead'
      ? SQALead
      : role === 'Software Engineer'
      ? Developer
      : role === 'Main SW Engineer'
      ? MainDeveloper
      : role === 'Lead SW Engineer'
      ? Lead
      : role === 'UI/UX Designer'
      ? Designer
      : role === 'Security Engineer'
      ? Security
      : role === 'Devops Engineer'
      ? Devop
      : role === 'Content Engineer'
      ? Content
      : // : role === 'Social Media Marketer'
      // ? Marketing
      role === 'HR Recruiter' || role === 'HR Ops Expert' || role === 'HR LnD Expert'
      ? HR
      : role === 'Office Admin'
      ? Admin
      : role === 'Business Analyst'
      ? BusinessAnalyst
      : role === 'CSM'
      ? CSM
      : role === 'Search Engine Optimiser'
      ? SEO
      : role === 'Photo/Videographer & Editor'
      ? PaidAds
      : role === 'Email Marketer'
      ? EmailMarketing
      : role === 'Key Account Handler'
      ? Sales
      : role === 'Business Developer'
      ? Business
      : role === 'Project Manager'
      ? ProjectManager
      : role === 'Social Media Marketer'
      ? SocialMediaMarketer
      : anyDep;
  return roleImage;
};

export const addResourceModalAvailability = (currentAvailability, totalAvailability, engagement) => {
  let val = 0;
  let arr = [];
  if (engagement && engagement.length > 0) {
    engagement.map((el) => (val = val + el.EmployeeProjects.engagementCapacity));
  }

  let options = totalAvailability - val;
  if (options < 0) {
    options = 0;
  }
  while (options) {
    arr.push({ value: options, label: options === totalAvailability ? `Fully Occupied` : `${options - 1}~${options} hours` });
    options = options - 2;
  }
  arr.push({ value: options, label: `Fully Available` });
  const capacityOptions = arr.sort((a, b) => {
    return a.value - b.value;
  });
  return capacityOptions;
};

//background depends upon availability
export const cardBGAvailability = (el) => {
  return (
    (0 === el.currentAvailability && '#ff9292') ||
    (el.totalAvailability === el.currentAvailability && '#96CEB4') ||
    (2 === el.currentAvailability && '#ffb2b2') ||
    (4 === el.currentAvailability && el.totalAvailability !== el.currentAvailability && '#F1E1A6') ||
    (6 === el.currentAvailability && el.totalAvailability !== el.currentAvailability && '#D7BDE2') ||
    (8 === el.currentAvailability && el.totalAvailability !== el.currentAvailability && '#f5cda8') ||
    (10 === el.currentAvailability && el.totalAvailability !== el.currentAvailability && '#C3E5AE')
  );
};

export const checkAvailability = (el) => {
  return 0 === el.currentAvailability
    ? 'Fully Occupied'
    : el.totalAvailability === el.currentAvailability
    ? 'Fully Available'
    : 2 === el.currentAvailability
    ? '1~2 hours'
    : 4 === el.currentAvailability && el.totalAvailability !== el.currentAvailability
    ? '3~4 hours'
    : 6 === el.currentAvailability && el.totalAvailability !== el.currentAvailability
    ? '5~6 hours'
    : 8 === el.currentAvailability && el.totalAvailability !== el.currentAvailability
    ? '7~8 hours'
    : 10 === el.currentAvailability && el.totalAvailability !== el.currentAvailability
    ? '9~10 hours'
    : '';
};

export const getAvailabilityCount = (el, availability) => {
  let result;
  if (availability === 0 || availability === 2) {
    result = el?.filter((item) => item.currentAvailability === availability);
  } else if (availability === 4 || availability === 6 || availability === 8 || availability === 10) {
    result = el?.filter((item) => item.currentAvailability === availability && item.currentAvailability !== item.totalAvailability);
  } else {
    result = el?.filter((item) => item.currentAvailability === item.totalAvailability);
  }
  return result;
};

export const UserPermissionsList = [
  { name: '/home', value: 'home' },
  { name: '/hr-dashboard', value: 'hrDashboard' },
  { name: '/resources', value: 'resources' },
  { name: '/resource-offboardings', value: 'my_department_off_boarding' },
  { name: '/upcoming-offboardings', value: 'all_off_boarding' },
  { name: '/tentative-offboardings', value: 'tentative_off_boarding' },
  { name: '/claim-requests', value: 'provident_funds' },
  { name: '/loan/all-requests', value: 'loan_requests' },
  { name: '/hardware-loss', value: 'hardware_loss' },
  { name: '/unassigned-hardware-requests', value: 'unassigned_hardware_requests' },
  { name: '/designations', value: 'designations' },
  { name: '/designation-levels', value: 'designation_levels' },
  { name: '/evaluation', value: 'resource_evaluation' },
  { name: '/attendance/', value: 'resources' },
  { name: '/resource-detail/', value: 'resources' },
  { name: '/projects', value: 'projects' },
  { name: '/project-detail/', value: 'projects' },
  { name: '/engagement-stats', value: 'summary' },
  { name: '/availability-stats', value: 'dashboard' },
  { name: '/company-division', value: 'executive_summary' },
  { name: '/onBoarding-reports', value: 'executive_summary' },
  { name: '/offBoarding-reports', value: 'executive_summary' },
  { name: '/portfolio', value: 'project_portfolio' },
  { name: '/email-marketing-domains', value: 'email_marketing_domains' },
  { name: '/position-openings', value: 'position_openings' },
  { name: '/project-onBoarding', value: 'onBoarding' },
  { name: '/engagement-leads', value: 'engagement_leads' },
  { name: '/skill-requests', value: 'skill_requests' },
  { name: '/board', value: 'board' },
  { name: '/organogram', value: 'organogram' },
  { name: '/audit-logs', value: 'logs' },
  { name: '/public-holidays', value: 'public_holidays' },
  { name: '/projecthistory/', value: 'projects' },
  { name: '/claim-request', value: 'claims' },
  { name: '/monthly-audit/reports', value: 'monthly_audit_report' },
];

export const checkPermission = (routeName) => {
  if (localStorage.getItem('is_admin') === 'true') return true;
  if (
    routeName === '/monthly-audit-form' ||
    routeName === '/home' ||
    routeName === '/notifications' ||
    routeName === '/leaves' ||
    routeName === '/hardware' ||
    routeName === '/hardware-loss' ||
    routeName === '/unassigned-hardware-requests' ||
    routeName === '/claim' ||
    routeName === '/loans' ||
    routeName === '/installments' ||
    routeName === '/provident-fund' ||
    routeName === '/offline-time' ||
    routeName === '/hr-offline-dashboard' ||
    routeName === '/profile' ||
    routeName === '/support/my-tickets' ||
    routeName === '/support/all-tickets' ||
    routeName === '/performance'
  )
    return true;
  let matchedRoute = UserPermissionsList.find((route) => routeName.includes(route.name));
  if (matchedRoute) {
    let userPermission = localStorage.getItem('permissions');
    if (userPermission == 'undefined' || userPermission == null || userPermission == 'null') return false;
    userPermission = JSON.parse(userPermission);
    if (userPermission[matchedRoute.value] && userPermission[matchedRoute.value].read == true) return true;
  }
  return false;
};

export const isMonthBetween = (startMonthString, endMonthString, specificMonthString) => {
  const startMonth = moment(startMonthString, 'YYYY-MM');
  const endMonth = moment(endMonthString, 'YYYY-MM');
  const specificMonth = moment(specificMonthString, 'YYYY-MM');
  return specificMonth?.isBetween(startMonth, endMonth, 'month', '[]');
};

export const WEBHOOK_PLATFORMS = [
  { value: 'invoteams', label: 'InvoTeams' },
  { value: 'invodesk', label: 'InvoDesk' },
  { value: 'invochat', label: 'InvoChat' },
  { value: 'involms', label: 'InvoLMS' },
];

export const WEBHOOK_EVENTS = [
  { value: 'create_resource', label: 'Create Resource', type: 'resource' },
  { value: 'update_resource', label: 'Update Resource', type: 'resource' },
  { value: 'delete_resource', label: 'Delete Resource', type: 'resource' },
  { value: 'restore_resource', label: 'Restore Resource', type: 'resource' },
  { value: 'create_project', label: 'Create Project', type: 'project' },
  { value: 'update_project', label: 'Update Project', type: 'project' },
  { value: 'delete_project', label: 'Delete Project', type: 'project' },
];

export const WEBHOOK_STATUSES = [
  { value: 'active', label: 'Active' },
  { value: 'paused', label: 'Paused' },
];

export const getWebhookSamplePayload = (type, event, token) => {
  let payload = {};

  switch (type) {
    case 'resource':
      payload = {
        body: {
          id: 1,
          name: 'John Doe',
          email: 'johndoe@gmail.com',
          designation: 'Assistant Software Engineer',
          experience: '0',
          currentAvailability: 'Fully Available',
          address: null,
          totalAvailability: '12 Hrs',
          dob: '1993-01-01',
          country: null,
          gender: 'MALE',
          createdAt: '2023-02-24T06:38:57.413Z',
          deletedAt: null,
          event: event,
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token,
        },
      };
      break;

    case 'project':
      payload = {
        body: {
          id: 1,
          name: 'Project Name',
          status: 'PRESALE',
          event: event,
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token,
        },
      };
      break;
  }

  return payload;
};

export const ENGAGEMENT_MANAGER = 'maria.tanveer@invozone.dev';

export const DESIGNATION_SLUGS = {
  CEO: 'ceo',
  PROJECT_MANAGER: 'project-manager',
  SQA: 'sqa-engineer',
};

export const DEPARTMENT_SLUGS = {
  HR: 'hr',
  BO: 'business-operations',
  SALES: 'sales',
  BD_CORPORATE: 'bd-c',
  BD_DYNAMIC: 'bd-a',
  BD_EXECUTIVE: 'bd-executive',
  BD_FAST_SALES: 'bd-f',
};

/**
 * List of leave types with corresponding values and labels.
 */
export const leaveTypeList = [
  { value: 'INTERNSHIP', label: 'Internship Leave' },
  { value: 'PROBATION', label: 'Probation Leave' },
  { value: 'NOTICE_PERIOD', label: 'Notice Period Leave' },
  { value: 'CASUAL', label: 'Casual Leave' },
  { value: 'SICK', label: 'Sick Leave' },
  { value: 'CARRY_FORWARD', label: 'Carry Forward Leave' },
  { value: 'UNPAID', label: 'Un-Paid Leave' },
];

export const permanentLeaveTypes = leaveTypeList.slice(-4);

/**
 * List of leave categories with corresponding values and labels.
 */
export const leaveCategories = [
  { value: 'HALF', label: 'Half Leave' },
  { value: 'FULL', label: 'Full Leave' },
];

/**
 * List of shift types with corresponding values and labels.
 */
export const shiftTypes = [
  { value: 'FIRST', label: 'First Half' },
  { value: 'SECOND', label: 'Second Half' },
];

/**
 * List of leave count types with corresponding values and labels.
 */
export const getLeaveCountTypes = (upTo) => {
  const leaveCountTypes = [
    { value: '0.25', label: '0.25' },
    { value: '0.75', label: '0.75' },
  ];

  return leaveCountTypes.filter((item) => parseFloat(item.value) <= upTo);
};

/**
 * List of work shifts
 */
export const workShiftOptions = [
  { label: 'Morning (10am-7pm)', value: 'morning1' },
  { label: 'Morning (11am-8pm)', value: 'morning' },
  { label: 'Evening (2pm-11pm)', value: 'evening1' },
  { label: 'Evening (3pm-12am)', value: 'evening' },
  { label: 'Night (6pm-3am)', value: 'night' },
];
