const initialState = {
  employeePips: [],
  hrPips: [],
  pmPips: [],
  hodPips: [],
  tentativeOffboardings:[]
};

const employeePipsReducer = (state = initialState, action) => {
  switch (action.type) {
    // case 'CREATE_EMPLOYEE_PIP_EXPECTATION':
    //   return {
    //     ...state,
    //     createdEmployeePips: [
    //       {
    //         ...state.createdEmployeePips[state.createdEmployeePips.length - 1],
    //         expectations: action.payload.expectations,
    //       },
    //     ],
    //   };
    // case 'CREATE_EMPLOYEE_PIP_CONCERN':
    //   return {
    //     ...state,
    //     createdEmployeePips: [
    //       {
    //         ...state.createdEmployeePips[state.createdEmployeePips.length - 1],
    //         areaOfConcern: action.payload.areaOfConcern,
    //       },
    //     ],
    //   };
    // case 'CREATE_EMPLOYEE_PIP_GOALS':
    //   return {
    //     ...state,
    //     createdEmployeePips: [
    //       {
    //         ...state.createdEmployeePips[state.createdEmployeePips.length - 1],
    //         goalsData: [
    //           {
    //             goalName: action.payload.goalName,
    //             goalDescription: action.payload.goalDescription,
    //             startDate: action.payload.startDate,
    //             deadLine: action.payload.deadLine,
    //           },
    //         ],
    //       },
    //     ],
    //   };
    case 'GET_EMPLOYEE_PIP':
      return {
        ...state,
        employeePips: action.payload,
      };
    case 'GET_PM_PIP':
      return {
        ...state,
        pmPips: action.payload,
      };
    case 'GET_HR_PIP':
      return {
        ...state,
        hrPips: action.payload,
      };
    case 'GET_HOD_PIP':
      return {
        ...state,
        hodPips: action.payload,
      };
    case 'GET_TENTATIVE_OFFBOARDINGS':
      return {
        ...state,
        tentativeOffboardings: action.payload,
      };
    default:
      return state;
  }
};

export default employeePipsReducer;
