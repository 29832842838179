import { CLEAR, CREATE_SKILL, DELETE_SKILL, GET_ALL_SKILLS, SEARCH_INPUT, SkILLS, SKILL_INPUT } from "../actions/Types";

const initialState = {
    skills: [],
    text: '',
    filterText: '',
    updateSkill: '',
    skillsSuggestion: [],
};
function reducer(state, action) {
  if (action.type === 'SkILLS') {
    return {
      ...state,
      skills: action.payload,
    };
  }
  if (action.type === 'CREATE_SKILL') {
    return {
      ...state,
      text: '',
    };
  }
  if (action.type === 'DELETE_SKILL') {
    return {
      ...state,
      skills: state.skills.filter((el) => el.id !== action.id),
    };
  }
  if (action.type === 'SKILL_INPUT') {
    return { ...state, text: action.value };
  }
  if (action.type === 'SEARCH_INPUT') {
    return { ...state, filterText: action.value };
  }
  if (action.type === 'CLEAR') {
    return { ...state, filterText: '', text: '' };
  }

  return state;
}

const skillsReducer = (state = initialState, action) => {

    // Employee Skills
    if (action.type === GET_ALL_SKILLS) {
        return { ...state, skills: action.payload };
    }

  if (action.type === 'SKILLSSUGGESTION') {
    return {
      ...state,
      skillsSuggestion: action.payload,
    };
  }

    if (action.type === 'LOADING_ACTION') {
        return {
            ...state,
            showLoader: action.payload,
        };
    }

    //  Skill Engagement
    if (action.type === SkILLS) {
        return {
            ...state,
            skills: action.payload,
        };
    }
    if (action.type === CREATE_SKILL) {
        return {
            ...state,
            text: '',
        };
    }
    if (action.type === DELETE_SKILL) {
        return {
            ...state,
            skills: state.skills.filter((el) => el.id !== action.id),
        };
    }
    if (action.type === 'UPDATE_SKILL') {
        return {
            ...state,
            updateSkill: action.payload,
        };
    }
    if (action.type === SKILL_INPUT) {
        return { ...state, text: action.value };
    }
    if (action.type === SEARCH_INPUT) {
        return { ...state, filterText: action.value };
    }
    if (action.type === CLEAR) {
        return { ...state, filterText: '', text: '' };
    }

    return state;
}

export default skillsReducer;